import React from "react";

// reactstrap components
import { 
    Container
} from "reactstrap";

import ali from '../assets/img/index_img/ali_profile.jpg';
import muizz from '../assets/img/index_img/muizz_profile.jpeg';

function SectionAboutUs() {

return (
    <div className="about-us-amsoo">
        <div
            className="about-page-header section-dark"
            style={{
            backgroundImage:
                "url(" + require("assets/img/index_img/about_us_hero.jpg") + ")",
            }}
        >
            <div className="dark-overlay">
                <div className="content-center">
                    <Container>
                        <h1 className="presentation-title">About Us</h1>
                    </Container>
                </div>
            </div>
        </div>
        <Container>
            <div className="about-us-text">
                <h4> <b>
                    We’re two brothers with a deep connection to Austin, a city we fell 
                    in love with during our college years. Our time here inspired us to create 
                    an affordable and comfortable housing option for others looking to call 
                    Austin home. We specialize in renting rooms in spacious houses, offering 
                    the benefits of living in a large, welcoming space without the high cost 
                    of traditional rentals. What sets us apart is our focus on creating a sense 
                    of community. Our shared living spaces are perfect for building new 
                    connections, especially for those new to the city. We know how important 
                    it is to feel at home, and we’re dedicated to helping our tenants settle 
                    in comfortably. Though we don’t live on-site, we are hands-on property 
                    managers who prioritize maintaining high standards for our homes and 
                    providing excellent support. Whether you’re a young professional, 
                    a student, or someone seeking a fresh start in Austin, we’re here to offer 
                    not just a place to live, but a place to belong.</b>
                </h4>
            </div>
            <div className="meet-the-team">
                <div className="meet-team-title">Meet The Team</div>
                <div className="team-cards">
                    <div className="team-card">
                        <img src={muizz} alt="muizz" style={{'height': '200px', borderRadius: '100px'}}></img>
                        <div className="team-name">Muizz Soomar</div>
                        <div className="team-title">Managing Member</div>
                    </div>
                    <div className="team-card">
                        <img src={ali} alt="ali" style={{'height': '200px', borderRadius: '100px'}}></img>
                        <div className="team-name">Ali Soomar</div>
                        <div className="team-title">Managing Member</div>
                    </div>
                </div>
            </div>
        </Container>
    </div>
);
}

export default SectionAboutUs;