import React from "react";

// reactstrap components
import { 
    Container
} from "reactstrap";


function SectionAvailability() {

return (
    <div className="footer-section">
        <Container>
            <div className="footer-container">
                <div className="left-footer">
                    <div className="footer-link">
                        <a href="/#Availability">Rooms Available</a>
                    </div>
                    <div className="footer-link">
                        <a href="/interestform">Interest Form</a>
                    </div>
                    <div className="footer-link">
                        <a href="/about-us">About Us</a>
                    </div>
                </div>
                <div className="right-footer">
                    Contact Us
                    <br/> Call or text: <a href="tel:+1512-655-9835" style={{color: "#099790", textDecoration: "none", fontWeight: "bold"}}> (512) 655-9835 </a>
                    <br/><a href="mailto:info@amsooproperties.com" style={{color: "#099790", textDecoration: "none", fontWeight: "bold"}}>info@amsooproperties.com</a>
                </div>
            </div>
        </Container>
    </div>
);
}

export default SectionAvailability;