import React from "react";
import SectionAboutUs from "components/SectionAboutUs";

function AboutUs() {

    return (
        <div className="nonhome-page">
            <SectionAboutUs />
        </div>
    )
}

export default AboutUs;